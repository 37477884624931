import { colors } from './colorsMapping';
import { ProductKey } from '@/themes/types';
import { PageType } from '@/components/product/types';

const JOY_COLOR = '#330066';
const SHOOTT_COLOR = '#C4974C';

const baseTheme = {
  colors: {
    primary: colors.purple,
    primaryHover: `color-mix(in srgb, ${colors.purple} 80%, black)`,
    primary20: `color-mix(in srgb, ${colors.purple} 20%, white)`,
  },
  slider: {
    color: colors.purple,
    marginLeft: 5,
    direction: 'row',
  },
};

const productThemes: Record<ProductKey, Partial<typeof baseTheme>> = {
  [PageType.LP]: {
    slider: {
      color: colors.blackText,
      marginLeft: 0,
      direction: 'column',
    },
  },
  [PageType.BASE]: {},
  [PageType.BASEV2]: {},
};

export const partnerThemes = {
  joy: {
    colors: {
      primary: JOY_COLOR,
      primaryHover: `color-mix(in srgb, ${JOY_COLOR} 80%, black)`,
      primary20: `color-mix(in srgb, ${JOY_COLOR} 20%, white)`,
    },
  },
  shoott: {
    colors: {
      primary: SHOOTT_COLOR,
      primaryHover: `color-mix(in srgb, ${SHOOTT_COLOR} 80%, black)`,
      primary20: `color-mix(in srgb, ${SHOOTT_COLOR} 20%, white)`,
    },
  },
} as const;

export type PartnerKey = keyof typeof partnerThemes | null;

export const createTheme = (pageType: ProductKey, partner?: PartnerKey) => {
  const productTheme = productThemes[pageType] ?? {};
  const partnerTheme = partner ? partnerThemes[partner] ?? {} : {};

  return {
    ...baseTheme,
    ...productTheme,
    ...partnerTheme,
  };
};

import styled from 'styled-components';
import { useEffect } from 'react';

type OverlayProps = {
  open: boolean;
  onClick?: () => void;
  variant?: 'light' | 'dark';
  children?: React.ReactNode;
  ariaLabel?: string;
};

const StyledOverlay = styled.div<{ open: boolean; variant: 'light' | 'dark' }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ variant }) =>
    variant === 'light' ? 'rgba(0, 0, 0, 0.45)' : 'rgba(0, 0, 0, 0.75)'};
  z-index: 999;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  touch-action: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const Overlay = ({
  open,
  onClick,
  variant = 'dark',
  children,
  ariaLabel = 'Background overlay',
}: OverlayProps) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open && onClick) {
        onClick();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open, onClick]);

  return (
    <StyledOverlay
      open={open}
      onClick={onClick}
      variant={variant}
      role="dialog"
      aria-modal="true"
      aria-label={ariaLabel}
      onMouseDown={(e) => e.target === e.currentTarget && onClick?.()}
    >
      {children}
    </StyledOverlay>
  );
};

export default Overlay;

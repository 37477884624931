import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSizeGuide } from '@/store/viewer/viewerSlice';
import { RootState } from '@/store';
import { Metafields, Product } from '@/types/ecommerce.types';
import { Panel, CloseButton, Header, Title, Content } from './SizeGuide.styles';
import SizeVisualizer from './SizeVisualizer';
import useLockBodyScroll from '@/hooks/useLockBodyScroll';
import SizeOptions from './SizeOptions';
import { getCurrentVariant } from '@/utils/utils';
import { withQuery } from '../product/data/withQuery';
import { CloseIcon } from './icons/CloseIcon';
import Overlay from '../templates/overlay/Overlay';
import getProductDescription from '../product/shared/getProductDescription';

interface SizeGuideProps {
  product: Product;
  shopMetafields: Metafields;
}

const SizeGuide = ({ product, shopMetafields }: SizeGuideProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.viewer.isSizeGuideOpen);
  const { mount } = getProductDescription(product, shopMetafields);
  const variants = product?.variants?.edges;
  const currentVariant = getCurrentVariant(variants);
  const initialSize = currentVariant?.selectedOptions[0].value;

  const [selectedSize, setSelectedSize] = useState(initialSize);

  useLockBodyScroll(isOpen);

  const handleSizeChange = (size: string) => {
    setSelectedSize(size);
  };

  useEffect(() => {
    const initialSize = currentVariant?.selectedOptions[0].value;
    setSelectedSize(initialSize);
  }, [currentVariant]);

  useEffect(() => {
    const mainElement = document.querySelector('body.template-product main') as HTMLElement | null;
    if (!mainElement) return;

    if (isOpen) {
      const originalZIndex = mainElement.style.zIndex || '';
      mainElement.style.zIndex = '9999';

      return () => {
        mainElement.style.zIndex = originalZIndex;
      };
    }
  }, [isOpen]);

  return (
    <>
      <Overlay open={isOpen} onClick={() => dispatch(closeSizeGuide())} />
      <Panel isOpen={isOpen}>
        <Header>
          <Title>Size guide</Title>
          <CloseButton onClick={() => dispatch(closeSizeGuide())}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Content>
          <SizeOptions
            selectedSize={selectedSize}
            onSizeChange={handleSizeChange}
            product={product}
          />
          <SizeVisualizer frameSize={selectedSize} mount={mount || ''} />
        </Content>
      </Panel>
    </>
  );
};

export default withQuery(SizeGuide);

import styled from 'styled-components';
import { Product } from '@/types/ecommerce.types';
import { withQuery } from '../product/data/withQuery';
import VariantList, { BaseVariantList } from '../shared/VariantList';

const SizeOptionsContainer = styled(BaseVariantList)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 80px);
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
`;

type SizeOptionsProps = {
  selectedSize: string;
  onSizeChange: (size: string) => void;
  product: Product;
};

const SizeOptions = ({ selectedSize, onSizeChange, product }: SizeOptionsProps) => {
  const variants = product?.variants?.edges;

  if (!product || !variants) {
    return null;
  }

  const sizes = variants.map((variant) => variant.node.selectedOptions[0].value);

  return (
    <VariantList
      options={sizes}
      selectedOption={selectedSize}
      onOptionSelect={onSizeChange}
      as={SizeOptionsContainer}
    />
  );
};

export default withQuery(SizeOptions);

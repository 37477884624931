import styled from 'styled-components';

export const Panel = styled.div<{ isOpen: boolean }>`
  position: fixed;
  background: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  @media (max-width: 799px) {
    bottom: 0;
    left: 0;
    right: 0;
    height: 72vh;
    transform: translateY(${({ isOpen }) => (isOpen ? '0' : '100%')});
    transition: transform 0.3s ease-in-out;
  }

  @media (min-width: 800px) {
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 700px;
    transform: translateX(${({ isOpen }) => (isOpen ? '0' : '100%')});
    transition: transform 0.3s ease-in-out;
  }
`;

export const Header = styled.div`
  position: relative;
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease-in-out;
  width: 50px;
  height: 50px;

  @media (max-width: 799px) {
    margin-right: -10px;
  }

  &:hover {
    color: #000;
  }
`;

export const Content = styled.div`
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 70px);
`;

export const ImageContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  aspect-ratio: 4/3;
  background: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
`;

export const PlaceholderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

import styled from 'styled-components';

export const Wrapper = styled.section`
  background-color: rgb(241, 240, 236);
  padding: 50px 0;

  .subheader__group--tabs {
    display: none;
  }

  .R-AvatarThumbnail {
    float: left;
    margin-right: 20px;
  }
`;

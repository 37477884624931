import React, { useEffect, useRef, useState } from 'react';
import { Metafields, Product } from '@/types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import {
  getCurrentVariant,
  getMetafieldV2,
  quickShipDisplay,
  scrollToReviews,
} from '@/utils/utils';
import ProductReviewMock from '../shared/ProductReviewMock';
import QuickShipBadge from '../shared/quickShip/QuickShipBadge';
import * as S from './ProductCta.styles';
import FloatingCta from './FloatingCta';
import ButtonTitle from './ButtonTitle';
import { getPartner } from '@/utils/partner';

type PropsType = {
  product: Product;
  shopMetafields?: Metafields;
  showFloatingCta?: boolean;
  onUploadPhoto?: (e: React.MouseEvent) => void;
};

const ProductCta = ({
  product,
  shopMetafields,
  onUploadPhoto,
  showFloatingCta = true,
}: PropsType) => {
  const ctaEl = useRef<any>(null);
  const [fixed, setFixed] = useState(false);
  const metafields = product?.metafields;
  const productTitle = getMetafieldV2('group_product_name', metafields);
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const partner = getPartner();
  const availableForSale = !!currentVariant?.availableForSale;
  const quickShip = quickShipDisplay(product?.tags, shopMetafields);

  useEffect(() => {
    const onScroll = () => {
      setFixed(ctaEl.current?.getBoundingClientRect()?.top < 0);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const floatingCTA = showFloatingCta ? (
    <FloatingCta product={product} fixed={fixed} onUploadPhoto={onUploadPhoto} />
  ) : (
    <S.FloatingCTA fixed={fixed}>
      <div>
        <strong
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          {productTitle ?? product?.title}
        </strong>
        {!partner ? (
          <div onClick={scrollToReviews}>
            <ProductReviewMock size="small" />
          </div>
        ) : null}
      </div>
      <S.CtaButton
        id="fn-atc-button"
        name="add"
        type="submit"
        aria-label="Add to cart"
        className="tmplt-product__add-to-cart typo__button fn-product-atc"
        data-add-to-cart=""
        disabled={!availableForSale}
      >
        <ButtonTitle availableForSale={availableForSale} />
      </S.CtaButton>
    </S.FloatingCTA>
  );

  return (
    <>
      <S.CtaButtonContainer className="cta-button-container">
        {quickShip && <QuickShipBadge badgeSrc={quickShip} />}
        <S.CtaButton
          ref={ctaEl}
          id="fn-atc-button"
          name="add"
          type="submit"
          aria-label="Add to cart"
          className="tmplt-product__add-to-cart typo__button fn-product-atc"
          data-add-to-cart=""
          data-cy="cta-button"
          disabled={!availableForSale}
        >
          <ButtonTitle availableForSale={availableForSale} />
        </S.CtaButton>
      </S.CtaButtonContainer>
      {floatingCTA}
    </>
  );
};

ProductCta.displayName = 'ProductCta';
export default withQuery(ProductCta);

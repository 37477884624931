import React from 'react';
import styled from 'styled-components';

interface VariantListProps {
  options: string[];
  selectedOption: string;
  onOptionSelect: (option: string) => void;
  renderOption?: (option: string) => React.ReactNode;
  as?: React.ComponentType<any>;
}

export const BaseVariantList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 24px;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const VariantListItem = styled.li<{ isSelected: boolean }>`
  padding: 8px;
  min-width: 80px;
  min-height: 44px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? 'var(--color-primary)' : 'rgba(0, 0, 0, 0.1)')};
  color: ${({ isSelected }) => (isSelected ? 'var(--color-primary)' : 'inherit')};
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  box-shadow: ${({ isSelected }) => (isSelected ? `0 0 0 3px var(--color-primary-20)` : 'none')};

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  span {
    font-weight: 500;
    & + span {
      margin-top: 5px;
    }
  }
`;

const ARIA_LABEL = 'Product variant options';

const VariantList: React.FC<VariantListProps> = ({
  options,
  selectedOption,
  onOptionSelect,
  renderOption,
  as: VariantList = BaseVariantList,
}) => (
  <VariantList role="listbox" aria-label={ARIA_LABEL}>
    {options.map((option) => (
      <VariantListItem
        key={option}
        isSelected={selectedOption === option}
        onClick={() => onOptionSelect(option)}
        role="option"
        aria-selected={selectedOption === option}
        tabIndex={0}
      >
        {renderOption ? renderOption(option) : option}
      </VariantListItem>
    ))}
  </VariantList>
);

export default VariantList;

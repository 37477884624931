/* eslint-disable array-callback-return */
import { Link, navigate } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { resetGalleryItem } from '../../../store/product/productSlice';
import { setShouldCropToAll } from '../../../store/upload/uploadSlice';
import PriceWithDiscountYmal from './PriceWithDiscountYmal';
import QuickShipBadge from '../shared/quickShip/QuickShipBadge';
import { ProductTypes } from '../../../types/ecommerce.types';
import * as S from './RelatedProducts.styles';

import {
  getCurrentVariant,
  getMetafield,
  getMetafieldV2,
  handleizeProductType,
  parseGid,
  quickShipDisplay,
  getProductListingImage,
  getBlackLabelImage,
} from '../../../utils/utils';
import { ColorsProps, RelatedProductCardProps } from './RelatedProducts.types';
import TrackingService from '../../../services/TrackingService';
import { ItemListAttribution } from '../../../services/TrackingService.types';
import ProductBadge from '../shared/ProductBadge';
import { getPartner } from '@/utils/partner';

const RelatedProductCard: React.FC<RelatedProductCardProps> = ({
  pageProduct,
  product,
  index,
  ymalType,
  ymalProdBGClass,
  shopMetafields,
  shopMetaobjects,
}) => {
  const [colors, setColors] = useState<ColorsProps[]>([]);
  const dispatch = useDispatch();
  const tile = useSelector((state: RootState) => state.upload.tiles[0]);
  const partner = getPartner();
  const metafields = product?.metafields;

  const collectionId = getMetafield('group_product_id', metafields);
  const hasColorCollection = getMetafield('product_color_collection', metafields);

  const currentVariantFromPageProduct = getCurrentVariant(pageProduct?.variants?.edges, null, {
    checkParams: true,
  });
  const frameSize = currentVariantFromPageProduct?.selectedOptions[0]?.value;
  const isGallery = handleizeProductType(product?.productType) === ProductTypes.GALLERY;
  const isBlackLabel = handleizeProductType(product?.productType) === ProductTypes.BLACKLABEL;
  const groupProductName = getMetafield('group_product_name', metafields);
  const productTitle = String(groupProductName ?? '');

  const image = isBlackLabel
    ? getBlackLabelImage(product?.images?.edges)
    : getProductListingImage(product, partner);

  const price = product?.priceRange?.minVariantPrice?.amount;
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const comparePrice = currentVariant?.compareAtPrice?.amount;

  const galleryCount = Number(getMetafieldV2('gallery_item_count', metafields)) || 0;

  const variants = product.variants.edges;
  const displayVariant = String(getMetafieldV2('list_page_variation', metafields));
  const displayVariantID = Number(parseGid(displayVariant));

  const sizeVaild = variants.some(
    (variant) => variant?.node?.selectedOptions[0]?.value === frameSize
  );

  const params = new URLSearchParams();

  if (sizeVaild) {
    params.set('size', String(frameSize));
  }

  if (displayVariantID && !sizeVaild) {
    params.set('variant', String(displayVariantID));
  }

  const paramsArr = Array.from(params.keys()).length;
  const query = paramsArr > 0 ? `?${params.toString()}` : '';

  const url = `/products/${product?.handle}${query}`;

  useEffect(() => {
    let ignore = false;
    const CancelToken = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const result = await axios(
          `${process.env.SHOP_URL}/products/${product?.handle}?view=colors`,
          {
            cancelToken: CancelToken.token,
          }
        );
        if (!ignore) {
          setColors(result.data.colors);
        }
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log('Request canceled by user action: ', e);
        } else {
          console.log('Axios: Error fetching colorway data: ', e);
        }
      }
    };
    if (product?.handle && hasColorCollection && collectionId) {
      fetchData();
    }
    return () => {
      ignore = true;
      CancelToken.cancel('Operation canceled');
    };
  }, [product?.handle, collectionId, hasColorCollection]);

  if (product?.tags?.includes('Smart-Collections_Hidden')) {
    return null;
  }

  // Get productType, replace spaces with hypen, add as list item ID
  const productTypeHandle = product?.productType.replaceAll(' ', '-').toLowerCase();

  // If a corner display listing set true and pass to add class to list item
  const cornerListing = product.tags.includes('Cornershot-Listing') ? true : false;
  const featuredImage = JSON.parse(JSON.stringify(product))?.featuredImage;
  const imageWidth = featuredImage?.width;
  const imageHeight = featuredImage?.height || 1;
  const imageAspectRatio = imageWidth / imageHeight ?? 0;
  const dataRatio = isGallery
    ? 'gallery'
    : isBlackLabel
    ? 'black-label'
    : cornerListing === true
    ? 'corner'
    : imageAspectRatio === 1
    ? 'square'
    : imageAspectRatio < 1
    ? 'portrait'
    : 'landscape';

  const quickShip = quickShipDisplay(product?.tags, shopMetafields);

  const handleYmalClick = () => {
    const ymalSource =
      ymalType === 'recommended'
        ? 'ymal:' + pageProduct?.handle + '|source=recommended|source_id=' + pageProduct?.handle
        : 'ymal:' + pageProduct?.handle + '|source=collection|source_id=' + ymalType;

    const itemListAttribution: ItemListAttribution = {
      item_list_name: `YMAL: ${pageProduct?.title || 'unknown'}`,
      item_list_id: ymalSource || 'unknown',
      index: index,
      item_list_preview_image_id: image?.id?.toString() || undefined,
    };

    // Set localstorage on select_item for downstream event attribution
    localStorage.setItem('item_list_attribution', JSON.stringify(itemListAttribution));
    TrackingService.ga4Track('select_item', product, 1, {
      attributionOverride: itemListAttribution,
      sendCustomerImageData: false,
    });
    dispatch(resetGalleryItem());
    if (tile) {
      dispatch(setShouldCropToAll());
      // dispatch(setTile({ position: 0, isTransforming: false }));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      navigate(url);
    }, 250);
  };

  return (
    <div
      id={productTypeHandle}
      className={`${
        cornerListing === true ? 'cornershot-listing' : ''
      } background-${ymalProdBGClass}`}
    >
      {quickShip && <QuickShipBadge badgeSrc={quickShip} />}
      <S.Article
        data-id={parseGid(product?.id ?? '')}
        data-type={product?.productType}
        data-price={price}
        data-gallery={isGallery ? galleryCount : null}
        className={`product-thumbnail-ymal__slide ${
          isBlackLabel ? 'product-thumbnail-ymal__angled' : ''
        }`}
        onClick={(e) => handleYmalClick()}
        isGallery={isGallery}
      >
        <div>
          <Link to={url} onClick={(e) => e.preventDefault()} className="rv-wrapper-href">
            <figure>
              <S.ProductImageWrapper>
                <ProductBadge productTags={product?.tags || []} shopMetaobjects={shopMetaobjects} />
                <img
                  src={image?.src ?? ''}
                  alt=""
                  className="responsive-image__image product-thumbnail-ymal__thumbnail lazyautosizes lazyloaded"
                  data-aspectratio={imageAspectRatio}
                  data-ratio={dataRatio}
                />
              </S.ProductImageWrapper>
              {/* {isBlackLabel ? <div className="product-thumbnail__round-background"></div> : ''} */}
            </figure>
          </Link>
          <div className="product-thumbnail-ymal__details">
            {hasColorCollection ? (
              <ul>
                {colors?.map((item, index) => {
                  let urlColors = `${item.url}?size=${frameSize}`;
                  const swatchCount = colors.length - 4;

                  if (index <= 3) {
                    return (
                      <li
                        className={`${
                          item.handle === product?.handle
                            ? 'product-thumbnail__connected-collection-item--current'
                            : ''
                        }`}
                        key={item.handle}
                      >
                        <Link
                          to={urlColors}
                          className={`product-thumbnail__connected-collection-link color-${item?.color_name?.toLowerCase()}`}
                          title={item.color_name}
                          style={{ backgroundColor: item.color }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            setTimeout(() => {
                              navigate(urlColors);
                            }, 250);
                          }}
                        />
                      </li>
                    );
                  }

                  if (index + 1 === colors.length) {
                    return <li key={index}>{swatchCount > 0 ? `+${swatchCount}` : ''}</li>;
                  }
                })}
              </ul>
            ) : null}

            <header className="product-carousel__info">
              <Link to={url} className="rv-title-href">
                {productTitle}
              </Link>
              <span
                className="product-carousel__price rv-price-href-inner"
                style={{ display: 'flex' }}
              >
                From{' '}
                <PriceWithDiscountYmal
                  isGallery={isGallery}
                  galleryItemCount={galleryCount}
                  basePrice={price}
                  comparePrice={comparePrice}
                />
              </span>
            </header>
          </div>
        </div>
      </S.Article>
    </div>
  );
};

RelatedProductCard.displayName = 'RelatedProductCard';
export default RelatedProductCard;

import styled from 'styled-components';
import { useRef, useEffect, useState, useMemo } from 'react';

const ROOM_VISUALIZER_IMAGE =
  'https://cdn.shopify.com/s/files/1/0565/4895/0212/files/Frameology-Room_View-Background-Transparent-2025_0102.png?v=1735849435';

type SizeVisualizerProps = {
  frameSize: string;
  mount: string;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
`;

const FrameWrapper = styled.div<{ $isTabletop: boolean }>`
  height: 66.2%;
  display: flex;
  align-items: ${(props) => (props.$isTabletop ? 'flex-end' : 'center')};
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
`;

const FrameBox = styled.div<{ $width: number; $height: number; $isTabletop: boolean }>`
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  border: 0;
  background: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(15px, ${(props) => Math.sqrt(props.$width * props.$height) * 0.2}px, 20px);
  z-index: ${(props) => (props.$isTabletop ? 2 : 0)};
  margin-bottom: ${(props) => (props.$isTabletop ? '-5px' : '0')};
`;

const SizeVisualizer = ({ frameSize, mount }: SizeVisualizerProps) => {
  const [width, height] = useMemo(() => frameSize.split('x').map(Number), [frameSize]);
  const isTabletop = useMemo(() => mount === 'Tabletop', [mount]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [scaleFactor, setScaleFactor] = useState(17);

  const { scaledWidth, scaledHeight } = useMemo(
    () => ({
      scaledWidth: width * scaleFactor,
      scaledHeight: height * scaleFactor,
    }),
    [width, height, scaleFactor]
  );

  useEffect(() => {
    const updateScaleFactor = () => {
      if (!containerRef.current) return;

      const rafId = requestAnimationFrame(() => {
        const containerWidth = containerRef.current?.offsetWidth;
        if (!containerWidth) return;

        const newScaleFactor = (containerWidth / 1350) * 17;
        if (Math.abs(newScaleFactor - scaleFactor) > 0.1) {
          setScaleFactor(newScaleFactor);
        }
      });

      return () => cancelAnimationFrame(rafId);
    };

    updateScaleFactor();

    const resizeObserver = new ResizeObserver(() => {
      updateScaleFactor();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [scaleFactor]);

  return (
    <Container ref={containerRef}>
      <Image src={ROOM_VISUALIZER_IMAGE} alt="Room visualization" />
      <FrameWrapper $isTabletop={isTabletop}>
        <FrameBox $width={scaledWidth} $height={scaledHeight} $isTabletop={isTabletop}>
          {frameSize.replace('x', '"x')}"
        </FrameBox>
      </FrameWrapper>
    </Container>
  );
};

export default SizeVisualizer;

import { PartnerKey, partnerThemes } from '@/themes/theme';

function isValidPartner(value: string | null): value is keyof typeof partnerThemes {
  return value !== null && Object.keys(partnerThemes).includes(value);
}

export const getPartner = (): PartnerKey => {
  const urlParams = new URLSearchParams(window.location.search);
  const partnerParam = urlParams.get('partner');

  if (isValidPartner(partnerParam)) {
    return partnerParam;
  }
  return null;
};

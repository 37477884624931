import React from 'react';
import { ProductTypes, RouteProps } from '@/types/ecommerce.types';
import { withQuery } from './data/withQuery';
import { getMetafieldListItem, getMetafieldV2 } from '@/utils/utils';
import styled, { ThemeProvider } from 'styled-components';
import { PageType } from './types';

import ProductBase from '@/components/product/productBase/ProductBase';
import ProductBaseV2 from '@/components/product/productBase/ProductBaseV2';
import ProductLP from '@/components/product/productLP/ProductLP';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useQuery } from '@apollo/client';
import { GET_COLLECTION_BY_HANDLE } from './data/productQuery';
import Loading from '@/components/templates/icons/Loading';
import { createTheme } from '@/themes/theme';
import { GlobalStyle } from '@/app/App.styles';
import { getPartner } from '@/utils/partner';

const LoadingScreen = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const productsMap: Record<string, React.ComponentType<RouteProps>> = {
  [PageType.LP]: ProductLP,
  [PageType.BASE]: ProductBase,
  [PageType.BASEV2]: ProductBaseV2,
};

const Product = ({ product, handle, shopMetafields, pageType }: RouteProps) => {
  const { type } = useSelector((state: RootState) => state.product);
  const partner = getPartner();
  const theme = createTheme(pageType ?? PageType.BASE, partner);
  const colorCollectionHandle = getMetafieldV2('product_color_collection', product?.metafields);

  const { data: collectionData } = useQuery(GET_COLLECTION_BY_HANDLE, {
    variables: {
      handle: colorCollectionHandle,
    },
    skip: !colorCollectionHandle,
  });
  const colorCollection = collectionData?.collectionByHandle;

  if (!product) {
    return (
      <LoadingScreen>
        <Loading />
      </LoadingScreen>
    );
  }

  const productType = getMetafieldListItem('product_pdp_type', product?.metafields);
  const arBuilderEnabled = getMetafieldV2('3d_builder_enabled', product?.metafields) === 'true';
  const isGallery = type === ProductTypes.GALLERY;

  let Component = productsMap[pageType ?? PageType.BASE];
  if ((pageType === PageType.BASE && arBuilderEnabled) || isGallery) {
    Component = productsMap[PageType.BASEV2];
  }

  if (pageType !== productType && pageType !== PageType.BASE) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Component
        product={product}
        shopMetafields={shopMetafields}
        handle={handle}
        colorCollection={colorCollection}
      />
    </ThemeProvider>
  );
};

export default withQuery(Product);

import { navigate } from '@reach/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Metafields, Product, ProductTypes } from '@/types/ecommerce.types';
import styled from 'styled-components';
import { RootState } from '@/store';
import { ProductItems, setGalleryItem } from '@/store/product/productSlice';
import { setShouldCropToAll, setValidation } from '@/store/upload/uploadSlice';
import { withQuery } from '../data/withQuery';
import ImageService from '@/services/ImageService';
import { getCurrentVariant, getFrameSizeFromVariant, parseGid } from '@/utils/utils';
import { ProductOptionsTitle } from './ProductOptions.styles';
import getProductDescription from '../shared/getProductDescription';
import PriceWithDiscount from '../cart/PriceWithDiscount';
import SizeGuideButton from '../sizeGuide/SizeGuideButton';
import VariantList, { BaseVariantList } from '../../shared/VariantList';
import { ProductVariantEdge } from 'shopify-storefront-api-typings';
import { getPartner } from '@/utils/partner';

const VariantContainer = styled(BaseVariantList)`
  margin-top: 13px;
  position: relative;
  padding: 3px;

  @media (min-width: 800px) {
    margin-left: -3px;
    margin-top: 10px;
  }
`;

type PropsType = {
  product: Product;
  showMeta?: boolean;
  className?: string;
  sectionTitle?: string;
  shopMetafields: Metafields;
  showPrice: boolean;
};

const ProductVariants = ({
  product,
  showMeta = true,
  className,
  sectionTitle,
  shopMetafields,
  showPrice = false,
}: PropsType) => {
  const dispatch = useDispatch();
  const variants = product?.variants?.edges;
  const currentVariant = getCurrentVariant(variants);

  const frameSizeInPixels = getFrameSizeFromVariant(currentVariant);
  const { type, items } = useSelector((state: RootState) => state.product);
  const partner = getPartner();
  const imageId = useSelector((state: RootState) => state.editor.imageId);
  const newPosition = String(imageId ?? 0);
  const tile = useSelector((state: RootState) => state.upload.tiles[newPosition]);
  const originalImageSize = tile?.originalImageSize;
  const { size: productSize } = getProductDescription(product, shopMetafields);

  useEffect(() => {
    ImageService.validateImageQuality(originalImageSize, frameSizeInPixels, (result: boolean) => {
      if (result === false) {
        dispatch(setValidation({ qualityIsOk: false, position: newPosition }));
      } else {
        dispatch(setValidation({ isLowRes: false }));
      }
    });
  }, [currentVariant]);

  if (!product || !currentVariant || type === ProductTypes.GALLERY) {
    return null;
  }

  const handleVariantSelect = (variantId: string) => {
    let url = `?variant=${parseGid(variantId)}`;
    if (partner) {
      url += `&partner=${partner}`;
    }
    navigate(url, { replace: true });
    if (tile) {
      dispatch(setShouldCropToAll());
    }

    items?.forEach((item: ProductItems, i: number) => {
      dispatch(
        setGalleryItem({
          index: i,
          obj: {
            imageIsLoading: true,
          },
        })
      );
    });
  };

  const renderVariantOption = (variant: ProductVariantEdge) => (
    <>
      <span>{variant.node.selectedOptions[0].value}</span>
      {showPrice && <PriceWithDiscount variant={variant} />}
    </>
  );

  return (
    <div className={className}>
      {sectionTitle && (
        <ProductOptionsTitle>
          <div>
            {sectionTitle}:<span>{productSize}</span>
          </div>
          <SizeGuideButton />
        </ProductOptionsTitle>
      )}
      <VariantList
        options={variants.map((v) => v.node.id)}
        selectedOption={currentVariant.id}
        onOptionSelect={handleVariantSelect}
        renderOption={(variantId) => {
          const variant = variants.find((v) => v.node.id === variantId);
          if (!variant) return null;
          return renderVariantOption(variant);
        }}
        as={VariantContainer}
      />
    </div>
  );
};
ProductVariants.displayName = 'ProductVariants';
export default withQuery(ProductVariants);

import TrackingService from '../../../services/TrackingService';

const FRAMEOLOGY_URL = process.env.SHOP_URL;

/**
 * @param  {number} product variant ID
 * @param  {number} product quantity
 * @return {promise}
 */
export const addToCart = (variantID: string | null, quantity: number) => {
  var data = {
    id: variantID,
    quantity: quantity,
  };

  return fetch(`${FRAMEOLOGY_URL}/cart/add.js`, {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'xmlhttprequest' /* XMLHttpRequest is ok too, it's case insensitive */,
    },
    method: 'POST',
  })
    .then(function (response) {
      return response.json();
    })
    .catch((error: unknown) => {
      console.error(error);
    });
};

/**
 * @param  {number} product variant ID
 * @param  {number} product quantity
 * @return {promise}
 */
export const changeCart = (key: string, quantity: number) => {
  var data = {
    id: key,
    quantity: quantity,
  };

  return fetch(`${FRAMEOLOGY_URL}/cart/change.js`, {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'xmlhttprequest' /* XMLHttpRequest is ok too, it's case insensitive */,
    },
    method: 'POST',
  })
    .then(function (response) {
      return response.json();
    })
    .catch((error: unknown) => {
      console.error(error);
    });
};

export const updateCartAttributes = () => {
  const { gaClientId, gaSessionId, gaSessionNumber } = TrackingService.getGaCookieData();
  const shopifyCustomerId = TrackingService.getUserId();
  const shopifyCartId = TrackingService.getCookie('cart');
  fetch(`${FRAMEOLOGY_URL}/cart/update.js`, {
    body: JSON.stringify({
      attributes: {
        framebuilderSessionId: TrackingService.getDeviceId(),
        shopifyCartId: shopifyCartId,
        shopifyCustomerId: shopifyCustomerId,
        gaClientId: gaClientId,
        gaSessionId: gaSessionId,
        gaSessionNumber: gaSessionNumber,
      },
    }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'xmlhttprequest' /* XMLHttpRequest is ok too, it's case insensitive */,
    },
    method: 'POST',
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('[Shopify Cart]: ', data);
    })
    .catch((error: unknown) => {
      console.error('[Shopify Cart]: ', error);
    });
};

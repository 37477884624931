import React from 'react';
import { Metafields, Product } from '@/types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getMetafieldJSON } from '@/utils/utils';
import * as S from './ProductFaqV2.styles';

type FaqItem = {
  question: string;
  answer: string;
  show: boolean;
};

type PropTypes = {
  className?: string;
  product: Product;
  shopMetafields: Metafields;
};

const ProductFaqV2 = ({ product, shopMetafields, className }: PropTypes) => {
  if (!shopMetafields || !product) {
    return null;
  }

  const accordionItems: FaqItem[] = [];
  const accordionQuestion = getMetafieldJSON('faq_question', shopMetafields);
  const accordionAnswer = getMetafieldJSON('faq_answer', shopMetafields);
  const accordionOptIn = getMetafieldJSON('faq_pdp_opt_in', shopMetafields);

  if (accordionQuestion && accordionAnswer) {
    accordionQuestion?.forEach((item: string, index: number) => {
      accordionItems.push({
        question: item,
        answer: accordionAnswer[index],
        show: !!accordionOptIn[index],
      });
    });
  }

  const data = [...accordionItems.filter((item) => item.show)];

  return (
    <S.WrapperColor className={className}>
      <S.Wrapper data-cy="product-faq">
        {data?.map((item, index) => (
          <div key={item.question}>
            <S.DetailsTitle className="details-title">
              {index + 1}. {item.question}
            </S.DetailsTitle>
            <S.Context dangerouslySetInnerHTML={{ __html: item.answer }} />
          </div>
        ))}
      </S.Wrapper>
    </S.WrapperColor>
  );
};
ProductFaqV2.displayName = 'ProductFaqV2';
export default withQuery(ProductFaqV2);

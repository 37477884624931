export const CloseIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M16.5 29.8337C23.8638 29.8337 29.8333 23.8641 29.8333 16.5003C29.8333 9.13653 23.8638 3.16699 16.5 3.16699C9.13619 3.16699 3.16666 9.13653 3.16666 16.5003C3.16666 23.8641 9.13619 29.8337 16.5 29.8337Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 12.5L12.5 20.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 12.5L20.5 20.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleSizeGuide } from '@/store/viewer/viewerSlice';
import { TitleOption } from '../options/ProductOptions.styles';
import MeasureIcon from '@/components/templates/icons/MeasureIcon';

const SizeGuideButton = () => {
  const dispatch = useDispatch();

  return (
    <TitleOption
      className="underline-link"
      onClick={(e) => {
        e.preventDefault();
        dispatch(toggleSizeGuide());
      }}
    >
      <MeasureIcon />
      Size guide
    </TitleOption>
  );
};

export default SizeGuideButton;

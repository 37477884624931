import React from 'react';
import { Router, Redirect } from '@reach/router';
import smoothscroll from 'smoothscroll-polyfill';
import { Provider } from 'react-redux';
import Product from '@/components/product';
import { store } from '@/store';
import { PageType } from '@/components/product/types';

// Uncomment to enable local LP page functionality
// window.pageProductCollections = 'canvas-lp-products';
// window.pageDefaultProduct = 'premium-canvas-print';
// window.pageHandle = 'lp-canvas';

// kick off the smooth scroll polyfill!
smoothscroll.polyfill();

const App = () => {
  const pageName = window.pageHandle;

  return (
    <Provider store={store}>
      <Router primary={false}>
        <Product path={`/pages/${pageName}/:handle`} pageType={PageType.LP} />
        {!!window.pageDefaultProduct && (
          <Redirect
            from={`/pages/${pageName}`}
            to={`/pages/${pageName}/${window.pageDefaultProduct}`}
            noThrow
          />
        )}
        <Product path={'/products/:handle'} pageType={PageType.BASE} />
      </Router>
    </Provider>
  );
};

export default App;

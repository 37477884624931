import { createSlice } from '@reduxjs/toolkit';
import { getAspectRatioFromPrintSize } from '../../utils/utils';

export interface ProductItems {
  title: string;
  sku: string;
  printSize: string;
  frameSize: string;
  aspectRatio: number;
  orientation: string;
  handle: string;
  imageIsLoading: boolean;
}

export interface ViewerState {
  editProductOptions: boolean;
  type: null | string;
  aspectRatio: number;
  productTitle: string;
  formatHandle?: string | null;
  items: ProductItems[];
  handle: string | null;
  galleryTiles: any;
  galleryItemTitles: {
    [key: string]: string;
  };
}

const initialState: ViewerState = {
  editProductOptions: false,
  type: null,
  aspectRatio: 1,
  productTitle: '',
  formatHandle: null,
  items: [],
  handle: null,
  galleryTiles: null,
  galleryItemTitles: {},
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    toggleEditProductOptions: (state) => {
      state.editProductOptions = !state.editProductOptions;
    },
    setProductType: (state, action) => {
      state.type = action.payload;
    },
    setAspectRatio: (state, action) => {
      state.aspectRatio = action.payload;
    },
    setProductTitle: (state, action) => {
      state.productTitle = action.payload;
    },
    setFormatHandle: (state, action) => {
      state.formatHandle = action.payload;
    },
    setResetGalleryItemTitle: (state) => {
      state.galleryItemTitles = {};
    },
    setGalleryItemTitle: (state, action) => {
      const { handle, title } = action.payload;
      state.galleryItemTitles[handle] = title;
    },
    setGalleryItem: (state, action) => {
      const { index, obj } = action.payload;
      const items = state.items.length ? [...state.items] : [];
      const item = items[index];
      items[index] = { ...item, ...obj };
      state.items = items;
    },
    resetGalleryItem: (state) => {
      state.items = [];
    },
    setHandle: (state, action) => {
      state.handle = action.payload;
    },
    setGalleryTiles: (state, action) => {
      state.galleryTiles = action.payload;
    },
    setItemOrientation: (state, action) => {
      const { orientation, position } = action.payload;
      const newTiles = [...state.items];
      const item = newTiles[position];
      if (item) {
        const newOrientation = orientation?.toLowerCase();
        const aspectRatio = getAspectRatioFromPrintSize(item.printSize, newOrientation);
        newTiles[position].orientation = newOrientation;
        newTiles[position].aspectRatio = aspectRatio;
        state.items = newTiles;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleEditProductOptions,
  setProductType,
  setAspectRatio,
  setProductTitle,
  setFormatHandle,
  setGalleryItem,
  resetGalleryItem,
  setItemOrientation,
  setGalleryItemTitle,
  setResetGalleryItemTitle,
  setHandle,
  setGalleryTiles,
} = productSlice.actions;

export default productSlice.reducer;

import { useLayoutEffect } from 'react';

export default function useLockBodyScroll(isLocked: boolean): void {
  useLayoutEffect((): (() => void) => {
    if (!isLocked) return () => {};

    const originalStyle: string = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = originalStyle);
  }, [isLocked]);
}

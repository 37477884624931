import { Link, navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../store';
import { setSelectedSkin, setSelectedSkinIndex } from '../../../store/editor/editorSlice';
import { resetGalleryItem } from '../../../store/product/productSlice';
import { setShouldCropToAll } from '../../../store/upload/uploadSlice';
import { withQuery } from '../data/withQuery';
import { Product, ProductTypes } from '../../../types/ecommerce.types';
import { handleizeProductType, getMetafieldV2, getCurrentVariant } from '../../../utils/utils';
import { getPartner } from '@/utils/partner';

const Wrapper = styled.div`
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 800px) {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  a {
    color: var(--color-primary);

    border-color: var(--color-primary);
  }

  .rarr {
    display: inline-block;
    border: 1px solid var(--color-primary);
    border-width: 0 1px 1px 0;
    padding: 3px;
    transform: rotate(-45deg);
  }
`;

const ProductHallwaySize = ({ product }: { product: Product }) => {
  const dispatch = useDispatch();
  const tile = useSelector((state: RootState) => state.upload.tiles[0]);
  const partner = getPartner();
  const currentVariantFromPageProduct = getCurrentVariant(product?.variants?.edges, null, {
    checkParams: true,
  });
  const frameSize = currentVariantFromPageProduct?.selectedOptions[0]?.value as string;

  if (!product?.hallway_size_product?.reference) return null;

  const { handle, title, group_title } = product.hallway_size_product.reference;
  const isArtisticProduct = handleizeProductType(product?.productType) === ProductTypes.ARTISTIC;
  const hallwayLabel = getMetafieldV2('hallway_size_label', product?.metafields);
  let url = `/products/${handle}?size=>${frameSize}`;

  if (partner) {
    url += `&partner=${partner}`;
  }

  return (
    <Wrapper>
      {hallwayLabel ? hallwayLabel : 'Want a larger size?'}{' '}
      <Link
        to={url}
        className="underline-link"
        onClick={(e) => {
          e.preventDefault();
          dispatch(resetGalleryItem());
          if (tile) {
            dispatch(setShouldCropToAll());
          }
          if (isArtisticProduct) {
            dispatch(setSelectedSkin(null));
            dispatch(setSelectedSkinIndex(0));
          }
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(() => {
            navigate(url);
          }, 250);
        }}
      >
        Shop the {group_title?.value || title}
        <span className="rarr" />
      </Link>
    </Wrapper>
  );
};

export default withQuery(ProductHallwaySize);
